@import 'variables';
@import 'mixins';
@import 'typography';
@import 'header';
@import 'footer';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrapper {
  overflow: hidden;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  @include desk-lg-below3 {
    max-width: 100%;
    padding: 0 15px;
  }
}
/*socialmedia*/
.socialmedia {
  position: relative;
  z-index: 1;
}

.socialmedia-dtls {
  padding: 180px 8px 0;
  @include tablet-portrait {
    padding: 95px 8px 0;
  }
  @include mobile-portrait {
    padding: 95px 0 0;
  }

  h2 {
    display: inline;
    vertical-align: top;
    line-height: 92px;
    float: left;
    width: calc(100% - 200px);
    width: -webkit-calc(100% - 200px);
    @include desk-lg-below3 {
      font-size: 95px;
    }
    @include tablet-portrait {
      font-size: 72px;
      line-height: 65px;
    }
    @include tablet-landscape1 {
      font-size: 48px;
      line-height: 47px;
    }
    @include mobile-landscape {
      font-size: 29px;
      line-height: 35px;
    }
    @include mobile-portrait {
      font-size: 29px;
      line-height: 35px;
    }

    span {
      display: inline-block;
      vertical-align: top;
      background: $bg-color4;
      padding: 20px 18px;
      @include tablet-portrait {
        padding: 10px;
      }
      @include tablet-landscape1 {
        padding: 6px;
      }
      @include mobile-landscape {
        padding: 5px 10px;
      }
    }
  }
}

.graphics-wrap {
  position: relative;
  margin: 0 0 0 -55px;
  @include tablet-landscape1 {
    margin: 0;
  }
  @include mobile-portrait {
    padding: 35px 0;
  }
}

.graphics-cnt {
  position: relative;
  z-index: 1;
  width: 53.4%;
  background: $white;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  float: right;
  padding: 64px 66px 37px 70px;
  margin: -20px -10px 20px 0;
  @include tablet-landscape1 {
    width: 100%;
    margin: 0;
  }
  @include mobile-portrait {
    padding: 35px;
  }
  @include mobile-portrait {
    padding: 25px;
    margin: 0 0 25px;
  }

  h4 {
    line-height: 30px;
    @include mobile-portrait {
      font-size: 20px;
      line-height: 22px;
    }
  }

  p {
    padding: 19px 0 24px;
    @include mobile-portrait {
      font-size: 18px;
      line-height: 25px;
    }
  }

  a {
    float: right;
  }

  .get {
    @include desk-lg-below3 {
      width: 75%;
    }
    @include tablet-portrait {
      width: 100%;
    }
  }
}

.levels {
  float: left;
  background: $bg-color3;
  padding: 11px 0;
  display: inline-block;
  vertical-align: top;
  width: 115px;
  text-align: center;
  @include mobile-portrait {
    width: 36%;
  }
  @include mobile-landscape {
    width: 99px;
    padding: 5px 0;
  }
}

.graphics {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 -75px;
  @include tablet-landscape1 {
    margin: 0;
    position: static;
  }

  figure {
    display: inline-block;
    vertical-align: top;

    img {
      display: block;
      margin: 0 auto;
    }
  }
}
/*main-cnt*/
.main-cnt {
  margin: 0 0 0 -33px;
  z-index: 0;

  ul {
    li {
      margin: 0;
      padding: 0 10px;
      display: inline-block;
      cursor: pointer;
    }
  }
}

.icons {
  position: relative; //        float: left;
  display: inline-block;

  .icons-cnt {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.7);
    height: 100%;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
  }
}
/*service*/
.service {
  padding: 150px 0 75px;
  @include tablet-portrait {
    padding: 75px 0;
  }
  @include tablet-landscape1 {
    padding: 35px 0;
  }

  h2 {
    display: inline;
    vertical-align: top;
    line-height: 92px;
    max-width: 475px;
    float: left;
    width: calc(100% - 200px);
    width: -webkit-calc(100% - 200px);
    @include desk-lg-below3 {
      font-size: 95px;
    }
    @include tablet-portrait {
      font-size: 80px;
      line-height: 65px;
    }
    @include mobile-landscape {
      font-size: 50px;
      line-height: 50px;
    }
    @include mobile-portrait {
      font-size: 32px;
      line-height: 32px;
    }

    span {
      display: inline-block;
      vertical-align: top;
      background: $bg-color4;
      padding: 20px 14px;
      color: $block;

      &.white {
        color: $white;
      }
    }
  }
}

.service-links {
  ul {
    max-width: 1080px;
    padding: 20px 0 0;
    @include tablet-portrait {
      padding: 35px 0 0;
    }
    @include tablet-landscape1 {
      padding: 0;
    }

    .links {
      text-align: center;
      cursor: pointer;
      width: 33.33%;
      display: inline-block;
      float: left;
      margin: 0 -5px 0 0;

      &.active {
        margin: -20px 0 0 -9px;
        width: 35.03%;
        @include tablet-landscape1 {
          width: 100%;
          margin: 0;
        }

        small {
          padding: 24px 0;
        }

        .design {
          p {
            padding: 7px 5px;
          }

          span {
            margin: 20px 0 28px;
            width: 100%;
            box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
          }
        }

        .great-design {
          padding: 18px 25px 38px;
          box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
          -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
          @include tablet-portrait {
            padding: 20px 20px 30px;
          }
        }
      }

      > span {
        display: inline-block;
        text-align: center;
        padding: 0 0 17px;
      }

      small {
        font-size: 38px;
        line-height: 40px;
        color: $white;
        display: block;
        text-align: center;
        padding: 20px 0;
        font-family: 'BrandonGrotesque-Black';
      }
      @include tablet-landscape1 {
        width: 100%;
        padding: 15px 0;
      }
    }
  }
}

.great-design {
  background: $white;
  padding: 18px 25px 30px;
  @include tablet-portrait {
    padding: 15px 15px 20px;
  }
}

.design {
  text-align: left;

  p {
    font-size: 30px;
    line-height: 32px;
    color: $block;
    padding: 5px 5px 7px;
    font-family: 'National-Light';
    @include desk-lg-below3 {
      font-size: 28px;
    }
    @include tablet-portrait {
      font-size: 20px;
      line-height: 22px;
      padding: 3px 5px;
    }

    b {
      padding: 0 0 0 18px;
      vertical-align: top;
    }
  }

  span {
    width: 100%;
    height: 3px;
    background: $bg-color6;
    display: block;
    margin: 16px 0;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  }
}

.start {
  text-align: center;

  span {
    font-size: 39px;
    line-height: 41px;
    color: $block;
    font-family: 'BrandonGrotesque-Black';
    display: block;
  }

  .get {
    width: 87%;

    &.get1 {
      background: $bg-color2;
      transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;

      &:hover {
        background: darken($bg-color2,25%);
      }
    }

    &.get2 {
      background: $bg-color5;
      transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;

      &:hover {
        background: darken($bg-color5,25%);
      }
    }

    &.get3 {
      background: $bg-color4;
      transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;

      &:hover {
        background: darken($bg-color4,25%);
      }
    }
  }

  p {
    padding: 6px 0 15px;
    font-family: 'National-Light';
    font-weight: bold;
  }
}

.great {
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);

  small {
    background: $bg-color2;
  }
}

.better {
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;

  small {
    background: $bg-color5;
  }
}

.best {
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);

  small {
    background: $bg-color4;
  }
}
/*custom*/
.custom {
  padding: 18px 0 54px;
  text-align: center;
  @include mobile-portrait {
    padding: 20px 0;
  }

  b {
    font-size: 30px;
    line-height: 34px;
    color: $block;
    font-family: 'BrandonGrotesque-Black';
    @include mobile-portrait {
      font-size: 25px;
      line-height: 30px;
    }
  }

  small {
    font-size: 31px;
    line-height: 34px;
    padding: 0 0 0 16px;
    letter-spacing: 0;
    color: $block;
    font-family: 'National-Light';
    font-weight: bold;
    @include mobile-portrait {
      font-size: 20px;
      line-height: 25px;
      padding: 0 0 0 10px;
    }
  }
}

.btn {
  text-align: center;
  margin: 22px 0;

  .get {
    width: 27%;
    @include tablet-landscape1 {
      width: 50%;
    }
  }
}
/*banner-bt*/
.banner-bt {
  img {
    width: 100%;
  }
}
/*index.html*/
/*designs*/
.designs {
  position: relative;
}

.designs-dtls {
  padding: 180px 0 0;
  @include tablet-landscape1 {
    padding: 80px 0 0;
  }

  h1 {
    display: inline-block;
    vertical-align: top;
    line-height: 128px;
    @include tablet-portrait {
      font-size: 115px;
      line-height: 97px;
    }
    @include tablet-landscape1 {
      font-size: 90px;
      line-height: 77px;
    }
    @include mobile-landscape {
      font-size: 60px;
      line-height: 40px;
    }
    @include mobile-portrait {
      font-size: 40px;
      line-height: 25px;
    }

    span {
      display: inline-block;
      vertical-align: top;
      background: $bg-color2;
      padding: 14px 28px 14px 18px;
      margin: 0 0 -28px;

      &:first-child {
        padding-top: 28px;
      }
      @include mobile-portrait {
        padding: 15px 23px 9px 18px;
      }
      @include tablet-portrait {
        margin: 0 0 -16px;
      }
      @include mobile-landscape {
        margin: 0 0 -2px;
      }
    }

    small {
      padding-bottom: 28px;
      color: $white;
      display: inline-block;
      vertical-align: top;
      background: $bg-color2;
      padding: 14px 24px 14px 19px;
      font-size: 157px;
      @include tablet-portrait {
        font-size: 115px;
        line-height: 97px;
      }
      @include tablet-landscape1 {
        font-size: 90px;
        line-height: 77px;
      }
      @include mobile-landscape {
        font-size: 60px;
        line-height: 40px;
      }
      @include mobile-portrait {
        font-size: 40px;
        line-height: 25px;
        padding: 10px 24px 10px 19px;
      }
    }
  }
}

.creative-wrap {
  position: relative;
  margin: -19px 0 0;
  @include tablet-landscape1 {
    margin: 0;
  }
}

.creative-cnt {
  width: 597px;
  background: $white;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  float: right;
  padding: 64px 66px 37px 70px;
  @include tablet-landscape1 {
    width: 551px;
  }
  @include mobile-landscape {
    width: 432px;
    padding: 25px;
  }
  @include mobile-portrait {
    width: 273px;
    padding: 15px;
  }

  h4 {
    line-height: 30px;
    @include mobile-portrait {
      line-height: 25px;
    }
  }

  p {
    padding: 19px 0 24px;
    @include mobile-portrait {
      font-size: 18px;
      line-height: 25px;
      padding: 15px 0;
    }
  }

  a {
    float: right;
  }

  .get4 {
    width: 46%;
    @include tablet-landscape1 {
      width: 70%;
    }
  }
}

.service-cnt {
  padding: 85px 0 0;
  @include tablet-landscape1 {
    padding: 50px 0 0;
  }
  @include mobile-portrait {
    padding: 30px 0 0;
  }

  h2 {
    display: inline;
    vertical-align: top;
    line-height: 92px;
    max-width: 607px;
    padding: 0 0 19px;
    float: left;
    width: calc(100% - 200px);
    width: -webkit-calc(100% - 200px);
    @include tablet-portrait {
      font-size: 90px;
    }
    @include tablet-landscape1 {
      font-size: 60px;
      line-height: 35px;
    }
    @include mobile-landscape {
      font-size: 50px;
      width: auto;
    }
    @include mobile-portrait {
      font-size: 40px;
      line-height: 15px;
    }

    span {
      display: inline-block;
      vertical-align: top;
      background: $bg-color2;
      padding: 13px 15px 10px 12px;
      margin: 0 0 -19px;
      color: $block;
      @include tablet-landscape1 {
        margin: 0;
      }

      small {
        display: inline-block;
        vertical-align: top;
        background: $bg-color2;
        color: $white;
        font-size: 110px;
        @include tablet-portrait {
          font-size: 90px;
        }
        @include tablet-landscape1 {
          font-size: 60px;
          line-height: 35px;
        }
        @include mobile-landscape {
          font-size: 50px;
        }
        @include mobile-portrait {
          font-size: 40px;
          line-height: 15px;
        }
      }
    }
  }
}

.social-links {
  padding: 0 0 149px;
}

.media-links {
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  @include tablet-landscape1 {
    padding: 0 0 50px;
  }
  @include mobile-portrait {
    padding: 0 0 30px;
  }

  ul {
    li {
      position: relative;
      float: left;
      display: inline-block;
      width: 33.33%;
      text-align: center;
      min-height: 360px;
      vertical-align: middle;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      -webkit-justify-content: center;
      align-items: center;
      -webkit-align-items: center;
      cursor: pointer;
      background: rgb(248,248,248);

      &:hover {
        .sub-link {
          visibility: visible;
          opacity: 1;
          -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
        }
      }
      @include tablet-landscape1 {
        width: 50%;
      }
      @include mobile-landscape {
        width: 100%;
      }

      &:nth-child(4n) {
        @include tablet-landscape1 {
          background: $white;
        }
        @include mobile-landscape {
          background: rgb(248,248,248);
        }
      }

      &:nth-child(4n+1) {
        background: $white;
      }

      &:nth-child(4n+2) {}

      &:nth-child(4n+3) {
        background: $white;
        @include tablet-landscape1 {
          background: rgb(248,248,248);
        }
        @include mobile-landscape {
          background: $white;
        }
      }

      &.media {}

      .report {
        span {
          padding: 31px 0 0;
          font-size: 20px;
          line-height: 22px;
          font-weight: normal;
          display: block;
          color: $block;
          font-family: 'BrandonGrotesque-Black';
          @include tablet-portrait {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

.sub-link {
  background: $white;
  padding: 25px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  z-index: 9999;
  @include tablet-portrait {
    padding: 15px;
  }

  figure {
    float: left;
  }

  span {
    float: left;
    padding: 25px 0 0 25px !important;
    @include tablet-portrait {
      padding: 10px 0 0 10px !important;
    }
  }

  a {
    float: right;
  }

  .get6 {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 100%;
    padding: 0;

    span {
      right: 13px;
    }
  }

  p {
    text-align: left;
    padding: 0 0 25px;
    @include tablet-portrait {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.sub-cnt {
  padding: 0 0 25px;

  img {
    max-width: 80%;
  }
}

.right-main {
  position: relative;
  background: url("../images/particles-shape.jpg") no-repeat center center;
  background-size: cover;
  min-height: 590px;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);

  &:before {
    content: "";
    position: absolute;
    left: -90px;
    top: -53px;
    background: url("../images/mobile.png") no-repeat center center;
    width: 792px;
    height: 793px;
    background-size: contain;
    @include desk-lg-below2 {
      left: -96px;
      top: -79px;
      width: 700px;
    }
    @include desk-lg-below3 {
      left: -127px;
      top: -101px;
      width: 647px;
    }
    @include tablet-landscape1 {
      position: static;
      display: inline-block;
    }
    @include tablet-portrait {
      left: -114px;
      top: -121px;
      width: 529px;
    }
    @include tablet-landscape1 {
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      padding-top: 100%;
    }
  }
}

.right-rt {
  float: right;
  width: 48%;
  padding: 39px 65px 0 0;
  @include desk-lg-below2 {
    padding: 39px 10px 0 0;
    width: 50%;
  }
  @include tablet-landscape1 {
    width: 100%;
    float: none;
    padding: 25px;
  }

  h4 {
    padding: 0 0 11px;
  }

  figure {
    padding: 0 0 30px;
  }

  p {
    padding: 0 0 19px;
    font-weight: bold;
  }

  .get5 {
    background: $white;
    color: $block;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;

    &:hover {
      background: darken($white,25%);
    }
    @include tablet-portrait {
      width: 80%;
    }

    span {
      background: url("../images/right-arrow.png") no-repeat right top;
    }
  }
}

.cnt-btm {
  padding: 0 0 150px;
}

.cnt-main {
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
}

.btm-main {
  float: right;
  width: 66.5%;
  @include tablet-landscape1 {
    width: 100%;
    float: none;
  }
}

.mobile {
  background: #ce1d2c;
  width: 33.5%;
  float: left;
  position: relative;
  min-height: 725px;
  overflow: hidden;
  padding: 77px 0 0;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    left: -75px;
    bottom: -130px;
    background: url("../images/mobile-blur.png") no-repeat center center;
    width: 589px;
    height: 665px;
  }
  @include tablet-landscape1 {
    width: 100%;
    float: none;
  }
}

.monitor {
  background: $white;
  overflow: hidden;
  position: relative;
  min-height: 359px;

  figure {
    float: right;
    padding: 23px 0 0;
  }

  &:after {
    content: "";
    position: absolute;
    left: -160px;
    bottom: -219px;
    background: url("../images/mobile-map.png") no-repeat center center;
    width: 767px;
    height: 617px;
    background-size: contain;
    @include tablet-portrait {
      width: 665px;
    }
  }
  @include tablet-landscape1 {
    width: 100%;
    float: none;
  }
}

.church {
  figure {
    float: left;
    max-width: 50%;

    img {
      height: 361px;
      object-fit: cover;
    }
  }
  @include tablet-portrait {
    width: 100%;
  }
}
/*--case-study start--*/
.header1 {
  background-image: linear-gradient(158deg, #d83f49, #b30f61);
  z-index: 1;
  position: static;

  .logo {
    a {
      color: #fff;
    }
  }

  nav ul li a {
    color: #fff;
  }

  .header_bt {
    padding: 153px 0 0;
    @include mobile-landscape {
      padding: 85px 0 0;
    }

    ul {
      display: block;

      li {
        display: inline-block;
        padding: 0 10px;

        a {
          font-size: 20px;
          line-height: 22px;
          color: #ffffff;
          font-weight: normal;
          font-family: 'BrandonGrotesque-Black';
          position: relative;
          @include mobile-portrait {
            font-size: 18px;
            line-height: 20px;
          }

          &:after {
            content: "/";
            position: absolute;
            right: -9px;
            top: 4px;
            color: #ffffff;
            font-size: 25px;
          }
        }

        &:last-child {
          a {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.header_cont {
  display: block;
  position: relative;
  z-index: 1;
  padding: 102px 0 0;
  @include tablet-landscape1 {
    padding: 30px 0 0;
  }

  &:after {
    @include desk-lg {
      background-size: contain;
      right: 0;
      width: 55%;
    }
    @include tablet-landscape1 {
      display: none;
    }
    content: "";
    background: url("../images/hand_img.png") no-repeat bottom right;
    bottom: -39px;
    position: absolute;
    right: -16%;
    background-size: cover;
    width: 1072px;
    height: 925px;
    z-index: -1;
  }
}

.header_cont_in {
  padding: 0 0 0 7px;
  display: block;

  .header_left {
    @include desk-lg-below {
      padding: 0 0 50px;
    }
    @include tablet-landscape1 {
      float: none;
      width: 100%;
    }
    padding: 0 0 153px;
    float: left;
    width: 45%;

    a {
      display: block;
    }

    p {
      @include tablet-portrait {
        font-size: 25px;
        line-height: 33px;
      }
      font-family: 'National-Light';
      font-size: 40px;
      line-height: 46px;
      color: #fff;
      font-weight: bold;
      padding: 28px 0 0;
    }
  }
}

.header_right {
  float: right;
  width: 100%;

  figure {
    img {
      display: none;
      @include tablet-landscape1 {
        display: block;
      }
    }
  }
}

.problem_in {
  display: block;

  .problem_cont {
    padding: 163px 0 162px;
    @include tablet-landscape1 {
      padding: 50px 0;
    }

    ul {
      display: block;
      font-size: 0;

      li {
        display: inline-block;
        width: 33.3%;
        vertical-align: top;
        padding: 0 20px;
        @include tablet-landscape1 {
          width: 100%;
          display: block;
          padding: 0 0 25px;
        }

        .problem_bk {
          h5 {
            font-family: 'BrandonGrotesque-Black';
            font-size: 20px;
            font-weight: 900;
            font-style: normal;
            padding: 0 0 9px;
            line-height: 22px;
            letter-spacing: normal;
            color: #000000;
          }

          p {
            font-family: 'National-Light';
            font-size: 20px;
            font-weight: normal;
            line-height: 31px;
            letter-spacing: normal;
            color: #000000;
          }
        }
      }
    }
  }
}

.img_block1 {
  figure {
    img {
      display: block;
      width: 100%;
    }
  }
}

.working_in {
  .work_bk {
    display: block;
    padding: 198px 0 205px;
    @include tablet-landscape {
      padding: 100px 0;
    }
    @include tablet-landscape1 {
      padding: 50px 0;
    }
  }
}

.work_lt {
  float: left;
  display: inline-block;
  width: 17%;
  padding: 17px 25px 0 0;
  @include tablet-landscape {
    width: 30%;
  }
  @include mobile-portrait {
    width: 100%;
    float: none;
    display: block;
  }

  figure {
    img {
      display: block;
      margin: 0 auto;
    }
  }

  h5 {
    color: #000000;
    font-size: 19px;
    line-height: 20px;
    font-family: 'BrandonGrotesque-Black';
    font-weight: normal;
    text-align: right;
    padding: 15px 0 2px;
    @include mobile-portrait {
      text-align: center;
    }
  }

  span {
    color: #9b9b9b;
    font-size: 16px;
    line-height: 21px;
    font-weight: normal;
    font-family: 'National-Light';
    text-align: right;
    display: block;
    @include mobile-portrait {
      text-align: center;
    }
  }
}

.work_rt {
  float: right;
  display: inline-block;
  width: 82%;
  @include tablet-landscape {
    width: 70%;
  }
  @include mobile-portrait {
    width: 100%;
    float: none;
    display: block;
  }

  p {
    font-size: 40px;
    font-weight: normal;
    font-style: italic;
    line-height: 47px;
    letter-spacing: normal;
    color: #000000;
    padding: 9px 0 0;
    @include tablet-landscape {
      font-size: 30px;
    }
    @include tablet-landscape1 {
      font-size: 25px;
      line-height: 30px;
    }
  }
}

.out_in {
  background: #f6f6f6;
}

.out_txt {
  padding: 0 0 184px 10px;
  @include tablet-portrait {
    padding: 0 0 150px;
  }
  @include tablet-landscape1 {
    padding: 0 0 50px;
  }
  display: block;

  .out_lt {
    float: left;
    display: inline-block;
    width: 40%;
    @include tablet-landscape1 {
      width: 100%;
      display: block;
      margin: 0 0 25px;
    }

    p {
      @include tablet-portrait {
        font-size: 30px;
        line-height: 40px;
      }
      @include tablet-landscape1 {
        font-size: 25px;
        line-height: 30px;
      }
      font-family: 'BrandonGrotesque-black';
      font-size: 40px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 53px;
      letter-spacing: normal;
      color: #ff4256;
    }
  }

  .out_rt {
    display: inline-block;
    float: right;
    width: 54.8%;
    @include tablet-landscape1 {
      width: 100%;
      display: block;
    }
    padding: 0 10px 0 0;

    p {
      font-family: 'National-Light';
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 32px;
      letter-spacing: normal;
      color: #000000;
      padding: 0 0 33px;
    }
  }
}

.img_block2 {
  display: block;

  figure {
    img {
      display: block;
      width: 100%;
    }
  }
}

.single_in {
  display: block;
  padding: 273px 0 0;
  @include tablet-portrait {
    padding: 80px 0 0 0 !important;
  }
  @include desk-lg-below {
    padding: 150px 0 0;
  }

  .single_bk {
    position: relative;

    &:after {
      @include tablet-landscape {
        background-size: 80%;
      }
      @include tablet-landscape1 {
        display: none;
      }
      @include tablet-portrait {
        top: 5px;
      }
      content: "";
      background: url("../images/small_img2.png") no-repeat;
      width: 615px;
      height: 723px;
      position: absolute;
      left: -132px;
      top: -41px;
    }

    .single_lt {
      float: left;
      width: 50%;
      position: relative;
      display: none;
      @include tablet-landscape1 {
        display: block;
        width: 100%;
        float: none;
      }

      figure {
        img {
          display: block;
          width: 100%;
        }
      }
    }

    .single_rt {
      float: right;
      width: 46.5%;
      @include tablet-landscape1 {
        display: block;
        width: 100%;
        float: none;
        padding: 30px 0;
      }
      @include tablet-landscape {
        padding: 100px 0;
      }
      padding: 195px 17px 161px 0;

      p {
        font-family: 'National-Light';
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 32px;
        letter-spacing: normal;
        color: #000000;
        padding: 0 0 33px;
      }
    }
  }
}

.img_block3 {
  background-image: linear-gradient(216deg, #d83f49, #b30f61);
  position: relative;
  z-index: 9;

  figure {
    img {
      margin: -55px 0 0;
      display: inline-block;
      width: 100%;
    }
  }
}

.img_bk_cont {
  display: block;
  padding: 62px 0 151px;
  @include tablet-landscape {
    padding: 40px 0;
  }
  max-width: 680px;
  margin: 0 auto;

  span {
    display: block;

    img {
      margin: 0 auto;
      display: block;
    }
  }

  p {
    @include tablet-landscape {
      font-size: 30px;
      line-height: 40px;
    }
    @include tablet-landscape1 {
      font-size: 25px;
      line-height: 30px;
    }
    font-size: 40px;
    font-weight: normal;
    line-height: 48px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 13px 0 3px;
  }
}

.img_block4 {
  @include tablet-landscape1 {
    padding-bottom: 0;
  }
  display: block;
  padding: 76px 0 144px;

  figure {
    img {
      display: block;
      width: 100%;
    }
  }
}

.simlar_in {
  display: block;
  max-width: 680px;
  margin: 0 auto;
  padding: 81px 0 0;

  p {
    font-family: 'National-Light';
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 32px;
    letter-spacing: normal;
    color: #000000;
    padding: 0 0 31px;
  }
}

.service_img {
  position: absolute;
  left: 0;
  right: 0;
  top: 58%;
  @include tablet-landscape {
    top: 64%;
    padding: 0 10px;
  }
  @include tablet-landscape1 {
    top: 65%;
  }
  @include mobile-landscape {
    top: 68%;
  }
  @include mobile-portrait {
    top: 59%;
  }
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);

  span {
    display: block;
    font-family: 'National-Regular';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #fff;
    text-align: center;
    padding: 0 0 10px;
    @include tablet-landscape1 {
      font-size: 12px;
      line-height: 12px;
    }
    @include mobile-landscape {
      font-size: 10px;
      line-height: 10px;
    }
  }

  h5 {
    font-family: 'BrandonGrotesque-Black';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #fff;
    text-align: center;
    display: block;
    @include mobile-landscape {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.services_in {
  display: block;
  padding: 0 0 292px;
  @include tablet-landscape1 {
    padding: 0 0 150px;
  }
}

.svg-img {
  display: none !important;
}

.services_bk {
  display: block;
  position: relative;
  padding: 138px 0 0;
  @include mobile-portrait {
    padding: 50px 0 0;
  }

  figure {
    @include desk-lg-below2 {
      width: 52%;
      margin: 0 auto;
    }
    @include mobile-portrait {
      width: 80%;
    }

    img {
      display: block;
      margin: 0 auto;
      @include desk-lg-below2 {
        width: 100%;
      }
    }
  }
}

.img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  @include tablet-landscape1 {
    width: 115px;
  }
  @include mobile-landscape {
    width: 95px;
  }
  @include mobile-portrait {
    width: 25px;
  }

  span {
    font-family: 'BrandonGrotesque-Black';
    font-size: 16px;
    padding: 0 0 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #000;
    text-align: center;
    display: block;
    @include mobile-portrait {
      display: none;
    }
    @include mobile-portrait {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &:hover {
    .act_in {
      @media only screen and (min-width:1025px) {
        display: block;
      }
    }

    span {
      color: #f00;
    }
  }

  a {
    display: block;

    img {
      display: block;
      margin: 0 auto;
      @include mobile-portrait {
        width: 30px;
      }

      &.png-img {
        opacity: 1;
        visibility: visible;
      }

      &.svg-img {
        opacity: 0;
        visibility: hidden;
        height: 0;
      }
    }
  }
}

.img2 {
  left: 10.2%;
  right: auto;
  top: 25%;
  @include tablet-landscape1 {
    left: 11.2%;
    top: 17%;
  }

  a {
    display: inline-block;
  }

  span {
    display: inline-block;
    vertical-align: top;
    margin: 33px 10px 0 0;
    @include mobile-portrait {
      display: none;
    }
  }
}

.img3 {
  left: auto;
  right: 88px;
  top: 25%;
  @include desk-lg-below2 {
    right: 22px;
  }

  a {
    display: inline-block;
  }

  span {
    display: inline-block;
    vertical-align: top;
    margin: 13px -15px 0 13px;
    text-align: left;
    @include mobile-portrait {
      display: none;
    }
    @include mobile-portrait {
      margin: 8px 0 0 -27px;
    }
  }
}

.img4 {
  left: 4.2%;
  right: auto;
  top: 64%;
  @include desk-lg-below2 {
    left: 1.5%;
  }
  @include tablet-landscape1 {
    left: 5.5%;
    top: 64%;
  }
  @include mobile-landscape {
    left: 1.5%;
  }

  a {
    display: inline-block;
  }

  span {
    display: inline-block;
    vertical-align: top;
    margin: 33px 10px 0 0;
    @include mobile-portrait {
      display: none;
    }
  }
}

.img5 {
  left: auto;
  right: 45px;
  top: 65%;
  @include desk-lg-below2 {
    right: 0;
  }
  @include mobile-landscape {
    right: 22px;
  }
  @include mobile-portrait {
    right: 0;
  }

  a {
    display: inline-block;
  }

  span {
    display: inline-block;
    vertical-align: top;
    margin: 13px -15px 0 13px;
    text-align: left;
    @include mobile-portrait {
      display: none;
    }
    @include mobile-portrait {
      margin: 0 0 0 -27px;
    }
  }
}

.img6 {
  right: 31%;
  bottom: -15%;
  margin: 0;
  top: auto;
  left: auto;
  @include tablet-landscape1 {
    bottom: -27%;
    right: 26%;
  }
  @include desk-lg-below2 {
    bottom: -20%;
  }
  @include mobile-landscape {
    bottom: -29%;
  }
  @include mobile-portrait {
    bottom: -35px;
  }

  span {
    padding: 10px 0;
  }
}

.img7 {
  left: 35%;
  bottom: -15%;
  margin: 0;
  top: auto;
  right: auto;
  @include tablet-landscape1 {
    left: 22%;
    bottom: -27%;
  }
  @include mobile-landscape {
    bottom: -33%;
  }
  @include mobile-portrait {
    bottom: -38px;
  }

  span {
    padding: 10px 0;
  }
}

.delived_in {
  display: block;
}

.delived_cont {
  border-top: 2px solid #ccc;
  padding: 56px 0 0;

  > ul {
    display: block;
    font-size: 0;

    > li {
      display: inline-block;
      width: 33.3%;
      padding: 0 20px;
      vertical-align: top;
      @include tablet-landscape1 {
        width: 100%;
        display: block;
        padding: 0 0 25px;
      }
    }
  }
}

.delived_bk {
  h5 {
    font-family: 'BrandonGrotesque-Black';
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #000;
    padding: 0 0 11px;
    display: block;
  }

  ul {
    display: block;

    li {
      display: block;

      p {
        font-family: 'National-Light';
        font-size: 20px;
        padding: 0 0 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 29px;
        letter-spacing: normal;
        color: #000;
        display: block;
      }
    }
  }
}

.act_in {
  background: #e5e5e5;
  width: 246px;
  padding: 15px;
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 99;
  margin: 0 auto;
  @include tablet-landscape1 {
    margin: 15px auto;
  }

  p {
    font-family: 'National-Light';
    font-size: 20px;
    padding: 0 0 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 29px;
    letter-spacing: normal;
    color: #000;
    display: block;
  }
}

.talk_in {
  text-align: center;
  padding: 128px 0;
  max-width: 1010px;
  margin: 0 auto;
  @include tablet-landscape {
    padding: 100px 0;
  }
  @include tablet-landscape1 {
    padding: 50px 0;
  }

  h2 {
    font-size: 40px;
    line-height: 44px;
    font-family: 'National-Light';
    font-weight: normal;
    padding: 0 0 31px;
    @include tablet-landscape {
      font-size: 30px;
      line-height: 40px;
    }
    @include tablet-landscape1 {
      font-size: 25px;
      line-height: 30px;
    }
  }

  a {
    width: 33%;
    @include mobile-landscape {
      width: 45%;
    }
    @include mobile-portrait {
      width: 100%;
    }
  }
}

.next_project {
  display: block;
  background: #eaeaea;
}

.next_in {
  display: block;
  @include tablet-landscape1 {
    padding: 30px 0;
  }
}

.next_lt {
  float: left;
  width: 51%;
  @include tablet-landscape1 {
    width: 100%;
    float: none;
    display: block;
    margin: 20px 0 0;
    padding: 0;
  }

  figure {
    margin: -49px 0 0;

    img {
      display: block;
      margin: 0 auto;
    }
  }
}

.next_rt {
  display: block;
  float: right;
  width: 44.2%;
  @include tablet-portrait {
    padding: 35px 0 0;
  }
  @include tablet-landscape1 {
    width: 100%;
    float: none;
    display: block;
    padding: 10px 0 0;
  }
  padding: 98px 0 0;

  h5 {
    font-family: 'BrandonGrotesque-black';
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 55px;
    letter-spacing: normal;
    color: #000;
  }

  a.get {
    @include tablet-landscape {
      width: 60%;
    }
    width: 43%;
    background: #fff;
    color: #000;
    display: block;
    margin: 24px 0 0;

    span {
      background: url("../images/right-arrow.png") no-repeat right top;
    }
  }
}

.animation {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;

  figure {
    position: absolute;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
  }
}

.ani-card1 {
  top: 0;
  right: 0;
  width: 100%;
}

.ani-card2 {
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}

.ani-card3 {
  right: -0;
  bottom: 0;
  width: 100%;
  z-index: 9;
}

.ani-card4 {
  left: 0;
  bottom: 0;
  width: 100%;
}

.ani-card5 {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

.ani-card6 {
  left: 0;
  top: 0;
  width: 100%;
}

.ani-card7 {
  left: 0;
  top: 0;
  width: 100%;
}

li:hover {
  @media(min-width:1025px) {
    .animation {
      opacity: 1;
      visibility: visible;
    }

    .ani-card1 {
      top: -84px;
      right: -58px;
      width: 100%;
    }

    .ani-card2 {
      right: -231px;
      top: 187px;
      width: 100%;
      z-index: 99;
    }

    .ani-card3 {
      right: -101px;
      bottom: -71px;
      width: 100%;
      z-index: 9;
    }

    .ani-card4 {
      left: -29px;
      bottom: -109px;
      width: 100%;
    }

    .ani-card5 {
      left: -167px;
      bottom: 37px;
      width: 100%;
      z-index: 99;
    }

    .ani-card6 {
      left: -176px;
      top: 42px;
      width: 100%;
    }

    .ani-card7 {
      left: -249px;
      top: -42px;
      width: 100%;
    }
  }
}

#canvas {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
