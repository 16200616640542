.header-bm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.header-dtls {
  padding: 27px 34px 27px 28px;
  transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  @include desk-lg-below3 {
    padding: 25px 15px;
  }
}

.sticky .header-bm {
  position: fixed;
  background: #ffffff;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
}

.sticky .header-dtls {
  padding: 20px 15px;
  @include desk-lg-below3 {
    padding: 18px 15px;
  }
}

.logo {
  display: inline-block;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
  font-weight: normal;
  font-family: 'BrandonGrotesque-Black';
  text-transform: uppercase;
  position: relative;
}

.logo a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000000;
}

nav {
  display: inline-block;
  float: right;
}

nav ul li {
  display: inline-block;
  float: left;
  padding: 0 10px;
  position: relative;
}

nav ul li a {
  display: inline-block;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
  font-weight: normal;
  font-family: 'BrandonGrotesque-Black';
  position: relative;
  transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}

nav ul li a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 2px;
  background: #000000;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}

nav ul li a:hover:after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

nav ul li:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  transform: rotate(25deg);
}

nav ul li:last-child:after {
  display: none;
}

.cnt_bt {
  padding: 77px 0 0;
  @include tablet-landscape1 {
    padding: 0;
  }

  ul {
    display: block;

    li {
      display: inline-block;
      padding: 0 10px;

      a {
        font-size: 20px;
        line-height: 22px;
        color: #000000;
        font-weight: normal;
        font-family: 'BrandonGrotesque-Black';
        position: relative;
        @include mobile-portrait {
          font-size: 18px;
          line-height: 20px;
        }

        &:after {
          content: "/";
          position: absolute;
          right: -9px;
          top: 4px;
          color: #000000;
          font-size: 25px;
        }
      }

      &:last-child {
        a {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
/*case-study*/
.header-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.sticky .header-main {
  position: fixed;
  background-image: linear-gradient(158deg, #d83f49, #b30f61);
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
}
