body{font-family:Arial;font-size: 16px;line-height:18px;font-weight: normal;color: #000;background: $bg-color1;}
h1,h2,h3,h4,h5,h6{padding: 0;margin: 0;}
h1{font-size: 157px;line-height: 159px;color:$block;font-weight: normal;font-family: 'BrandonGrotesque-Black';}
h2{font-size:110px;line-height:112px;color:$block;font-weight: normal;padding: 0;margin: 0;font-family: 'BrandonGrotesque-Black';display: inline-block;vertical-align: top;}
h3{display: block;font-size: 58px;line-height: 60px;color:$white;font-family: 'Flama-Bold';margin: 0;padding: 0;font-weight: normal;}
h4{font-size:20px;line-height:22px;color:$block;font-weight: normal; font-family: 'BrandonGrotesque-Black';}
h5{font-size: 23px;line-height: 25px;color: $white;padding: 0;margin: 0;font-weight: normal;font-family: 'Flama-Bold';}
h6{padding: 0 0 15px 0; font-size: 14px;}
p{font-size: 20px;line-height:30px;color: $block;font-weight: normal; font-family: 'National-Light';}
ul,ol{list-style-type: none;margin: 0;padding: 0;}
li{list-style-type: none;line-height: 1px;}
figure{margin: 0;padding: 0;}
img{max-width: 100%;height: auto;border: 0 none;}
.clear{clear: both;display: block;font-size: 1px;line-height: 1px;}
 .clearfix:after{content:"";display:block;clear:both;}
a{text-decoration: none;}
.get{display: inline-block;background: $color1;font-size: 20px;line-height: 22px;color: $white;font-weight: normal;font-family: 'BrandonGrotesque-Black';text-transform: uppercase;position: relative;text-transform: uppercase;padding: 14px 47px 12px 20px;border-radius: 23px;width: 63%;text-align: left;transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;
    @include tablet-portrait {font-size: 18px;}
@include mobile-portrait {font-size: 13px;}
    &:hover{background: darken($color1,25%)}
}
.get span{background: url(../images/right-arrow1.png) no-repeat right top;position: absolute;top: 13px;right: 17px;width: 28px;height: 23px;
}

@font-face {
  font-family: 'BrandonGrotesque-Black';
  src: url('../fonts/BrandonGrotesque-Black/BrandonGrotesque-Black.eot') format('embedded-opentype'),  
      url('../fonts/BrandonGrotesque-Black/BrandonGrotesque-Black.woff') format('woff'), 
      url('../fonts/BrandonGrotesque-Black/BrandonGrotesque-Black.ttf')  format('truetype'), 
      url('../fonts/BrandonGrotesque-Black/BrandonGrotesque-Black.svg#BrandonGrotesque-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonGrotesque-Regular';
  src: url('../fonts/BrandonGrotesque-Regular/BrandonGrotesque-Regular.eot') format('embedded-opentype'),  
       url('../fonts/BrandonGrotesque-Regular/BrandonGrotesque-Regular.otf')  format('opentype'),
	   url('../fonts/BrandonGrotesque-Regular/BrandonGrotesque-Regular.woff') format('woff'), 
       url('../fonts/BrandonGrotesque-Regular/BrandonGrotesque-Regular.ttf')  format('truetype'), 
       url('../fonts/BrandonGrotesque-Regular/BrandonGrotesque-Regular.svg#BrandonGrotesque-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:  'National-Light';
  src: url('../fonts/National-Light/National-Light.eot') format('embedded-opentype'),  
      url('../fonts/National-Light/National-Light.woff') format('woff'), 
      url('../fonts/National-Light/National-Light.ttf')  format('truetype'), 
      url('../fonts/National-Light/National-Light.svg#National-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}



