/*footer*/

footer{background: $white;padding: 37px 0;
    @include mobile-portrait{padding: 25px 0;}    
}
.developer{display: block;}
/*society*/
.society{float: left;width: 25.83%;padding: 9px 0 0 0;
    @include tablet-portrait {width: 40%;}
    @include mobile-portrait{width: 100%;display: block;padding: 9px 0;}
}
/*term*/
.term{float: left;width: 33.33%;padding: 7px 0 0 0;
    @include tablet-portrait {width: 60%;}
    @include mobile-portrait{width: 100%;display: block;}
    p{font-size: 17px;line-height: 19px;padding: 0 0 5px 0;font-weight: bold;
    font-family: 'National-Light';color: $color14;}  
    ul{
        li{
            float: left;display: inline-block;padding: 0 15px 0 0;
            a{display: block;text-decoration: underline;color: $color1;font-size: 14px;line-height: 16px;}
        }
    }
    a{display: block;text-decoration: underline;color: $color1;font-size: 14px;line-height: 16px;font-weight: bold;
    font-family: 'National-Light';
    
    }
    .fa-icons{padding: 41px 0 33px 0;
    span{background:$color1;width: 50px;height: 50px;display: inline-block;border-radius: 100%;position: relative;vertical-align: middle;text-align: center;margin: 0 0px 0 2px;transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;
        &:hover{background: darken($color1,25%)}
        }
    .fa{color: $white;font-size: 30px;line-height: 50px;}
        }
    .para{padding: 25px 0 0 0;
        p{font-size: 14px;line-height: 19px;font-weight: bold;
    font-family: 'National-Light';color: $color14;padding: 0 0 8px 0}
    }
}
/*newsletter*/
.newsletter{float: left;width: 40.83%;padding: 7px 0 0 0;
    @include tablet-portrait {width: 100%;}
    @include mobile-portrait{width: 100%;display: block;}
    a{display: block;width: 100%;}
    h4{padding: 25px 10px 5px 21px;color: $color14;
        @include mobile-portrait {padding:25px 10px 5px 7px;}
    }
    p{padding: 0 0 10px 20px;
    font-size: 17px;
    line-height: 28px;font-weight: bold;
    font-family: 'National-Light';color: $color14;
        @include mobile-portrait {padding: 0 0 10px 7px;}
    }
    ul{
        li{
            float: left;
            width: 25%;
            display: inline-block;
            padding: 9px 25px 9px 0px;
            @include mobile-portrait{padding: 9px;width: 50%;}
           &:last-child{padding: 0;}
        }
         
    }
}
/*form-group*/
.form-group{padding: 0 0 55px 0;
    @include mobile-portrait{padding: 0 0 25px 0;}
    form{
        width: 72%;
        float: left;
        @include tablet-portrait {width: 60%;}
        @include mobile-portrait{width: 100%;display: block;padding: 0 0 20px 0;}
       .form-cnt {width: 96%;
    height: 48px;
    padding: 0 18px 0 18px;
    font-size: 21px;
    line-height: 48px;
    color: #9b9b9b;
    outline: none;
    font-family: 'National-Light';
    border-radius: 25px;
    border:2px solid #9b9b9b;
           @include mobile-portrait{width: 100%;}
        }
    }
    a{
        width: 28%;
        float: left;
        @include tablet-portrait {width: 40%;}
        @include mobile-portrait{width: 100%;display: block;}
    }
}