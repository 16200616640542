$white:#ffffff;
$block:#000000;
$color1:#ff4256;
$color2:#e8235e;
$color3:#bfbfbf;
$color4:#a1a1a1;
$color5:#e8235e;
$color6:#241218;
$color7:#bd2251;
$color8:#cccccc;
$color9:#42283a;
$color10:#0f0d0d;
$color11:#300d19;
$color12:#421624;
$color13:#9b9b9b;
$color14:#4a4a4a;


// backgrouds
$bg-red:#f00;
$bg-color1:#f8f8f8;
$bg-color2:#59dcc4;
$bg-color3:#6423C0;
$bg-color4:#A05FDD;
$bg-color5:#2eb9e0;
$bg-color6:#ececec;