@mixin border-radius($radius){
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -o-border-radius: $radius;
    -ms-border-radius: $radius;
}

	@mixin transform1($transform_var1)
{
	transform:translateY($transform_var1);
	-moz-transform:translateY($transform_var1);
	-ms-transform:translateY($transform_var1);
	-o-transform:translateY($transform_var1);
	-webkit-transform:translateY($transform_var1);
	
	}

@mixin transform2{
    -webkit-transform:rotate(135deg); 
    -moz-transform:rotate(135deg);
    -o-transform:rotate(135deg); 
    transform:rotate(135deg);
}

@mixin transform3{
    -webkit-transform:rotate(-135deg); 
    -moz-transform:rotate(-135deg);
    -o-transform:rotate(-135deg); 
    transform:rotate(-135deg);
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;       
    -o-box-shadow: $shadow; 
    -ms-box-shadow: $shadow;
        box-shadow: $shadow;
}


@mixin transition1
    {
    transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    -ms-transition:all 0.3s ease-in-out;
    }

    @mixin desk-xlg1{
			@media(max-width:1800px)
			{@content;
				}
		}
    @mixin desk-xlg{
			@media(max-width:1640px)
			{@content;
				}
		}
	@mixin desk-lg{
			@media(max-width:1500px)
			{@content;
				}
		}
	@mixin desk-lg-below{
			@media(max-width:1410px)
			{@content;
				}
		}
@mixin desk-lg-below1{
			@media(max-width:1300px)
			{@content;
				}
		}
@mixin desk-lg-below2{
			@media(max-width:1200px)
			{@content;
				}
		}
@mixin desk-lg-below3{
			@media(max-width:1120px)
			{@content;
				}
		}
	/*-- media query for tablet */
	@mixin tablet-portrait {
  		@media(max-width:991px)
			{@content;
				}
  
		}
	@mixin tablet-landscape	{
  		@media(max-width:1024px)
			{@content;
				}
  
		}

    @mixin tablet-landscape1{
  		@media(max-width:767px)
			{@content;
				}
  
		}

		
	/*-- media query for mobile landscape */
	@mixin mobile-landscape {
  		@media (max-width:599px)
   		{  @content;}
  
		}
@mixin mobile-landscape1 {
  		@media (max-width:575px)
   		{  @content;}
  
		}
	/*-- media query for mobile portrait */
	@mixin mobile-portrait {
  		@media (max-width:479px)
   		{  @content;}
  
		}